import {useState, useEffect, useRef} from 'react';
// import {format, parseISO} from 'date-fns'
import {Button, Card, Table, Spin, Space, Input} from 'antd';
import {styled} from 'styled-components';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
// import {MetaMaskAuth} from '../../utils/MetaMaskAuth';
// import {ButtonAction} from '../ButtonAction/index';
// import {FaqBlock} from '../FaqBlock/index';

const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  overflow: hidden;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
     width: 100%;
  }
`;

const SpinWr = styled(Spin)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
`;

const Title = styled.div`
  margin: 76px 0 34px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
    margin: 24px 0 18px;
    font-size: 28px;
  }
  @media only screen and (max-width: 425px){
      font-size: 24px;
  }
`;

// const ButtonWr = styled.div`
//   margin: 0 auto 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 32px;
//   @media only screen and (max-width: 767px){
//     flex-flow: column;
//   }
// `;

// const ButtonContainer = styled.div`
//   width: 100%;
//   max-width: 200px;
// `;

// const BlockWr = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
// `;


export function Content({userAddress, setUserAddress, isSignPopupShown, setIsFileUpload, isConnected, isPopupOpen, setIsPopupOpen, signedToken, holders, isValidatorsPopupOpen, setIsValidatorsPopupOpen, isReadOnlyPopupOpen, setIsReadOnlyPopupOpen}) {

  // const [sortedHolders, setSortedHolders] = useState(holders);
  const [masters, setMasters] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
              }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#0958d9' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#B6D3E7',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [{
    title: '#',
    dataIndex: 'rowIndex',
    key: 'rowIndex',
    width: '3%',
  }, {
    title: 'address',
    dataIndex: 'address',
    key: 'address',
    width: '37%',
    ...getColumnSearchProps('address'),
  }, {
    title: 'possible stake amount',
    dataIndex: 'maxOldUserStaking',
    key: 'maxOldUserStaking',
    width: '20%',
  }, {
  //   title: 'last epoch',
  //   dataIndex: 'finalized_epoch',
  //   key: 'finalized_epoch',
  //   width: '10%',
  // }, {
    title: 'staked',
    dataIndex: 'oldUserStakedSANs',
    key: 'oldUserStakedSANs',
    width: '20%',
  }, {
    title: 'points',
    dataIndex: 'oldUserSupportPoints',
    key: 'oldUserSupportPoints',
    width: '20%',
  // }, {
  //   title: 'status',
  //   key: 'isOffline',
  //   dataIndex: 'isOffline',
  //   width: '7%',
  //   render: (_, record) => (
  //           <Tag color={record.isOffline ? 'volcano' : 'green'}>
  //             {record.isOffline ? 'offline' : 'online'}
  //           </Tag>
  //   ),
  // }, {
  //   title: 'last active',
  //   dataIndex: 'score_updated_at',
  //   key: 'score_updated_at',
  //   width: '13%',
  }];
  
  

  // const columnsQueue = [{
  //   title: '#',
  //   dataIndex: 'rowIndex',
  //   key: 'rowIndex',
  //   width: '3%',
  // }, {
  //   title: 'wallet',
  //   dataIndex: 'address',
  //   key: 'address',
  //   width: '37%',
  //   ...getColumnSearchProps('address'),
  // }, {
  //   title: 'last block',
  //   dataIndex: 'last_block_number',
  //   key: 'last_block_number',
  //   width: '10%',
  // }, {
  //   title: 'last epoch',
  //   dataIndex: 'finalized_epoch',
  //   key: 'finalized_epoch',
  //   width: '10%',
  // }, {
  //   title: 'latency, ms',
  //   dataIndex: 'ping_time',
  //   key: 'ping_time',
  //   width: '10%',
  // }, {
  //   title: 'support points',
  //   dataIndex: 'supportPoints',
  //   key: 'supportPoints',
  //   width: '10%',
  // }, {
  //   title: 'status',
  //   key: 'isOffline',
  //   dataIndex: 'isOffline',
  //   width: '7%',
  //   render: (_, record) => (
  //     <Tag color={record.isOffline ? 'volcano' : 'green'}>
  //             {record.isOffline ? 'offline' : 'online'}
  //           </Tag>
  //   ),
  // }, {
  //   title: 'last active',
  //   dataIndex: 'score_updated_at',
  //   key: 'score_updated_at',
  //   width: '13%',
  // }];
  

  const validatorsRef = useRef(null);
  // const nodesRef = useRef(null);

  useEffect(() => {
    if (holders.length === 0) {
      return;
    }

    const SortNumbers = (a, b) => {
      if (b.oldUserStakedSANs === null) {
        return -1;
      }
      if (a.oldUserStakedSANs === null) {
        return 1;
      } 
      if (a.oldUserStakedSANs === b.oldUserStakedSANs) {
        // return 0;
        return b.maxOldUserStaking - a.maxOldUserStaking
      }
      else {
        return b.oldUserStakedSANs - a.oldUserStakedSANs
      }
    }

    // const moreThanOneHourAgo = (date) => {
    //   if (!date) {
    //     return true;
    //   };
    //   const HOUR = 1000 * 60 * 60;
    //   const anHourAgo = Date.now() - HOUR;
    //    return anHourAgo > parseISO(date).getTime();
    // }
    // setSortedHolders(holders.filter(m => m.depositDataUploadedAt === null).sort((a, b) => SortNumbers(a, b)).map((a, i) => a.score_updated_at !== null ? {...a, score_updated_at: `${format(parseISO(a.score_updated_at), 'yyyy/MM/dd H:mm:ss')}`, isOffline: moreThanOneHourAgo(a.score_updated_at), supportPoints: Math.round(a.supportPoints), rowIndex: i + 1} : {...a, isOffline: moreThanOneHourAgo(a.score_updated_at), rowIndex: i + 1}));
    setMasters(holders.filter(m => m.maxOldUserStaking !== null).sort((a, b) => SortNumbers(a, b)).map((a, i) => a.updatedAt !== null && {...a, maxOldUserStaking: Math.round(a.maxOldUserStaking), oldUserStakedSANs: Math.floor(a.oldUserStakedSANs * 100) / 100 || 0, oldUserSupportPoints: Math.floor(a.oldUserSupportPoints * 100) / 100 || 0,  rowIndex: i + 1}));
  }, [holders]);
  
  const onClickScroll = (ref) => {
    ref.current.scrollIntoView();
  };

  return (
    <Wr>
      {holders.length === 0
        ? <SpinWr />
        :
        <>
          <Title ref={validatorsRef} id='TOP'>Stakers</Title>
          {/* <ButtonWr>
            <ButtonContainer>
              <ButtonAction colorType="secondary" isPopupOpen={isValidatorsPopupOpen} setIsPopupOpen={setIsValidatorsPopupOpen} text="Run validator node"/>
            </ButtonContainer>
            <ButtonContainer>
            {(!userAddress || !signedToken)
            ? <MetaMaskAuth key={1} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected}  text="Register validator"/>
            : <ButtonAction isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} text="Register validator" setIsFileUpload={setIsFileUpload} upload/>
            }
            </ButtonContainer>
          </ButtonWr> */}
          <Table locale={{emptyText: <div style={{height: '600px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>No data</div>}} dataSource={masters} columns={columns} scroll={{x: "min-content"}} rowKey="address" onChange={() => onClickScroll(validatorsRef)}/>
          {/* <Title ref={nodesRef}>Read only nodes</Title> */}
          {/* <ButtonWr>
            <ButtonContainer>
              <ButtonAction colorType="secondary" isPopupOpen={isReadOnlyPopupOpen} setIsPopupOpen={setIsReadOnlyPopupOpen} text="Run read-only node"/>
            </ButtonContainer>
            <ButtonContainer>
            {(!userAddress || !signedToken)
            ? <MetaMaskAuth key={2} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected} text="Register node"/>
            : <ButtonAction isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} text="Register node" setIsFileUpload={setIsFileUpload}/>
            }
            </ButtonContainer>
          </ButtonWr> */}
          {/* <Table locale={{emptyText: <div style={{height: '600px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>No data</div>}} dataSource={sortedHolders} columns={columnsQueue} scroll={{x: "min-content"}} rowKey='id' onChange={() => onClickScroll(nodesRef)}/> */}
        </>}
        {/* <BlockWr>
          <FaqBlock/>
        </BlockWr> */}
    </Wr>
  )
}
