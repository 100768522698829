import {Card, Typography, Grid} from 'antd';
import {styled} from 'styled-components';
import StoredAuth from '../../../utils/localStorage';

const {Title, Paragraph} = Typography;
const {useBreakpoint} = Grid;


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const H1Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;

const Text = styled(Paragraph)`
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;


export function InfoContent() {
  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const {sm} = useBreakpoint();

  return (
    <Wr>
      <FormWr>
        <H1Title>Exclusive staking for OG sanr.app users</H1Title>
        <Text>Every user of sanr.app who used SAN tokens on sanr.app has the possibility to stake their SAN tokens in order to receive rewards in points, which will be taken into account during the <a href="https://sanr.network" target="_blank" rel="noreferrer">sanr.network</a> airdrop.</Text>
        <Text>Snapshot of address was taken on 25 Jan 2024</Text>
        <Title level={!sm ? 3 :2}>Staking Rules</Title>
        <Text copyable={{text: '0x69604C575B5c52E1A40a694dF5fdA76F218CCf51'}}>1. Each of the addresses presented in the table below can stake SAN. Staking occurs by sending SAN to the ethereum mainnet address: <strong>0x69604C575B5c52E1A40a694dF5fdA76F218CCf51</strong></Text>
        <Text>2. If a user used an amount of less than 10k SAN on sanr.app, the maximum amount of SAN that can be staked is 10k SAN.</Text>
        <Text>3. If a user used an amount of more than 10k SAN on sanr.app, the maximum amount of SAN that can be staked is the amount of SAN they used on the network.</Text>
        <Title level={!sm ? 3 :2}>Rewards</Title>
        <Text>For every 10k SAN staked, the user receives 1 point per day.</Text>
        <Text>If the amount of stake is less than or greater than 10k SAN, the user receives a proportional amount of points.</Text>
        <Text>For example<br/>
           If a user stakes 5k SAN, then they will receive 0.5 points per day<br/>
           If a user stakes 15k SAN, then they will receive 1.5 points per day
        </Text>
        <Text>In addition to this, in the future there may be additional rewards for users who hold staked SAN until the release of the new token. Stay tuned for announcements.</Text>
        <Title level={!sm ? 3 :2}>Withdrawal</Title>
        <Text>To unstake tokens, please create a ticket in our <a href='https://discord.gg/SFKgvN3XKF' target="_blank" rel="noreferrer">Discord channel</a>.</Text>
        <Text copyable={{text: '0x69604C575B5c52E1A40a694dF5fdA76F218CCf51'}}>Staking address: <strong>0x69604C575B5c52E1A40a694dF5fdA76F218CCf51</strong></Text>
      </FormWr>
    </Wr>
  )
}